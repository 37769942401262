import React from "react";

export function Input(props: React.InputHTMLAttributes<HTMLInputElement>) {
  const { className: _className, placeholder, ...rest } = props;
  const className = `${_className} form-input `;
  return (
    <input
      {...rest}
      className={className}
      placeholder={placeholder}
      onWheel={(e) => e.currentTarget.blur()}
      autoComplete="off"
    ></input>
  );
}
type InputWithTextProps = {
  optionsAvailable?: number;
} & React.InputHTMLAttributes<HTMLInputElement>;

export function Label(props: React.LabelHTMLAttributes<HTMLLabelElement>) {
  const { className: _className, ...rest } = props;
  const defaultClass = ` form-label `;
  return <label {...rest} className={`${defaultClass + _className} `}></label>;
}

export function Error({ text }: { text?: string }) {
  return <p className="text-[9px] text-red-500 my-0.5">{text}</p>;
}

export function HStack(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `flex flex-row ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function VStack(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `flex flex-col ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function Center(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `flex items-center justify-center ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function Box(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `${props.className}`;
  return <div {...props} className={className}></div>;
}

export function TextArea(
  props: React.TextareaHTMLAttributes<HTMLTextAreaElement>
) {
  const { className: _className, ...rest } = props;
  const className = `${_className} form-input `;
  return (
    <textarea {...rest} className={className} autoComplete="off"></textarea>
  );
}
