import { LockClosedIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { signInWithGoogle, signOutOfWebsite } from "../firebase";

export default function GoogleSignIn() {
  const navigate = useNavigate();
  useEffect(() => {
    signOutOfWebsite();
  }, []);
  return (
    <>
      <div className="flex flex-col h-screen py-12 px-4 sm:px-6 lg:px-8 w-full max-w-md space-y-8 justify-between">
        <div>
          <img
            className="h-8 w-auto"
            src={
              "https://www.rulezero.com/wp-content/uploads/2021/08/logo_rulezero_dark.png"
            }
            alt="hissa_logo"
          />
          <h2 className="mt-6 text-left text-3xl font-bold tracking-tight text-gray-900">
            Sign in to your Rulezero account
          </h2>
        </div>
        <div>
          <img
            className="w-auto mx-auto"
            src={
              "https://img.freepik.com/free-vector/company-employees-sharing-thoughts-ideas_74855-5469.jpg?w=2000"
            }
            alt="sharing"
          />
        </div>

        <div className="space-y-8">
          <button
            type="submit"
            className="login-with-google-btn group relative mt-32 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => {
              signInWithGoogle(navigate);
            }}
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <LockClosedIcon
                className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                aria-hidden="true"
              />
            </span>
            Sign in with Google
          </button>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center">
              <div className="w-4 h-2 rounded-full border-gray-500 border mx-1"></div>
              <div className="w-4 h-2 rounded-full border-gray-500 border mx-1"></div>
              <div className="w-8 h-2 rounded-full bg-gray-500 mx-1"></div>
            </div>
            <div className="flex flex-row ">
              <button
                type="submit"
                className="flex mr-4 justify-center rounded-md border border-transparent border-indigo-600 py-2 px-4 text-sm font-medium text-indigo-600 hover:bg-slate-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => {
                  navigate("/onboarding/planning");
                }}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
