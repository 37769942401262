import Router from "./routes";
import { Dialog, ThemeProvider } from "@mui/material";
import { muiTheme } from "./theme/mui";
import { ToastContainer } from "react-toastify";

export default function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <Router />
    </ThemeProvider>
  );
}
