import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Center, HStack } from "../shared/utility";
import Avatar from "./Avatar";

function limitString(str: string, limit: number) {
  if (str.length > limit) {
    return str.substring(0, limit).concat(" . . .");
  } else {
    return str.substring(0, limit);
  }
}

const RecommendationCard = ({
  id,
  name,
  type,
  price,
  description,
  location,
  affiliationLink,
  username,
}: {
  id: string;
  name: string;
  type: string;
  price: number;
  description: string;
  location: string;
  affiliationLink: string;
  username: string;
}) => {
  const [showmore, setShowMore] = useState(true);
  return (
    <div key={id} className="bg-white rounded-lg shadow-lg py-2 px-6 m-2 mx-4">
      <div className="text-left border-b border-gray-700">
        <p className="text-xl font-bold">{name}</p>
        <HStack className="justify-between mt-0">
          <p className="text-lg text-gray-600 mt-0">{type}</p>
          <HStack className="items-center 888c8c">
            <Icon
              icon="bx:rupee"
              color={price === 5 ? "#FFD700" : "#FFFFFF"}
              height={18}
            />
            <Icon
              icon="bx:rupee"
              color={price >= 4 ? "#FFD700" : "#FFFFFF"}
              height={18}
            />
            <Icon
              icon="bx:rupee"
              color={price >= 3 ? "#FFD700" : "#FFFFFF"}
              height={18}
            />
            <Icon
              icon="bx:rupee"
              color={price >= 2 ? "#FFD700" : "#FFFFFF"}
              height={18}
            />
            <Icon
              icon="bx:rupee"
              color={price >= 1 ? "#FFD700" : "#FFFFFF"}
              height={18}
            />
          </HStack>
        </HStack>
      </div>
      <div className="mt-1">
        <p className="text-base text-black font-normal">
          {showmore ? limitString(description, 100) : description}
          <span
            onClick={() => {
              setShowMore(!showmore);
            }}
          >
            {description.length < 100
              ? ""
              : showmore
              ? "...show more"
              : "...show less"}
          </span>
        </p>
      </div>
      <div className="mt-8 text-center flex flex-row gap-4">
        {location !== "" && (
          <a
            href={location}
            target="_blank"
            rel="noopener noreferrer"
            className="flex gap-4 items-center w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <p>Location</p>
            <Icon icon="mdi:navigation-variant" color="eef1f6" height={18} />
          </a>
        )}
        {affiliationLink !== "" && (
          <a
            href={affiliationLink}
            target="_blank"
            rel="noopener noreferrer"
            className="flex gap-4 items-center w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <p>Link Out</p>

            <Icon icon="ic:outline-link" color="eef1f6" height={18} />
          </a>
        )}
      </div>
      <HStack className="items-center mt-2">
        {/* {username !== "" && (
          <Center className="relative w-8 h-8 rounded-full loader-linear cursor-pointer">
            <Center
              style={{
                backgroundColor: "#4f46e5",
                color: "ffffff",
              }}
              className="flex w-8 h-8 rounded-full overflow-hidden"
            >
              <img
                src={localStorage.getItem("profilePic") || ""}
                alt="profilePic"
              />
            </Center>
          </Center>
        )} */}

        <p>{username !== "" ? username : "Anonymous"}</p>
      </HStack>
    </div>
  );
};

export default RecommendationCard;
