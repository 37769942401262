import create from "zustand";
import { configurePersist } from "zustand-persist";

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: "root",
});

const persist = _persist as any;

export interface CultAuthModel {
  id: string;
  name: string;
}

export type CultAuthState = {
  cultAuth: CultAuthModel;
  setCultAuth: (cultAuth: CultAuthModel) => void;
  reset: () => void;
};

const initialState = {
  cultAuth: { email: "", name: "", imageUrl: "" },
};

const CultAuthStore = create<CultAuthState>(
  persist(
    {
      key: "cultAuth",
    },
    (set: any) => ({
      ...initialState,
      setCultAuth: (cultAuth: CultAuthModel) => {
        set((state: any) => ({ cultAuth: cultAuth }));
      },
      reset: () => {
        set(() => initialState);
      },
    })
  )
);

export const useCultAuthStore = CultAuthStore;
