import { Formik, Form, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import {
  Input,
  Label,
  Error,
  VStack,
  TextArea,
  HStack,
} from "../shared/utility";
import { db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { CultAuthModel, useCultAuthStore } from "../steps/loginState";
import { Icon } from "@iconify/react";
import { Select } from "../shared/Select";
import Avatar from "../card/Avatar";
import { toast } from "react-toastify";
import { Button, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SwitchButton } from "../card/SwitchButton";

export interface RecommendationCreation {
  userName?: string;
  userId?: string;
  recommendationName: string;
  description: string;
  recommendationType: string;
  location?: string;
  rupeeCount?: string;
  affilationLink?: string;
}

const initialValues: RecommendationCreation = {
  recommendationName: "",
  recommendationType: "",
  description: "",
  userName: "",
  userId: "",
  location: "",
  rupeeCount: "",
  affilationLink: "",
};

const recommendationSchema = Yup.object().shape({
  recommendationName: Yup.string()
    .max(50)
    .required()
    .label("Recommendation Name"),
  recommendationType: Yup.string()
    .max(50)
    .required()
    .label("Recommendation Type"),
  description: Yup.string().required().label("Description"),
});

const SugestionCreation = () => {
  const cultAuthStore = useCultAuthStore();
  const [affordable, setAffordable] = useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [hideName, setHideName] = React.useState(false);
  const username = localStorage.getItem("name") || "";

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const onSubmit = async (
    values: RecommendationCreation,
    { resetForm }: FormikHelpers<RecommendationCreation>
  ) => {
    console.log("upload");

    handleClick();
    await setDoc(doc(db, "recommendation", uuidv4()), {
      description: values.description || "",
      link: values.affilationLink || "",
      location: values.location || "",
      name: values.recommendationName || "",
      rupeeCount: affordable || 0,
      type: values.recommendationType || "",
      userid: cultAuthStore.cultAuth.id || "",
      username: hideName ? "" : username,
    });
    resetForm();
    setAffordable(0);
    setHideName(false);
  };
  return (
    <div className="h-screen flex flex-col justify-between">
      <div className=" flex flex-col ">
        <HStack className="px-4 py-2 -mx-12 sticky top-0 left-0 right-0 bg-white w-full  items-center justify-between shadow border-b border-gray-200 ">
          <img
            className="h-8 w-auto"
            src={
              "https://www.rulezero.com/wp-content/uploads/2021/08/logo_rulezero_dark.png"
            }
            alt="hissa_logo"
          />
          <Avatar />
        </HStack>
        <div className="px-4 mt-12">
          <Formik
            enableReinitialize={true}
            validationSchema={recommendationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                <VStack className="gap-4  max-h-full justify-between ">
                  <div>
                    <Label
                      htmlFor="recommendationType"
                      className="block text-sm font-medium text-gray-700"
                    >
                      RecommendationType
                    </Label>

                    <Select
                      options={[
                        "Restaurants",
                        "App/Website",
                        "Product",
                        "Destination",
                        "Book",
                        "Movie/Web Series",
                        "Others",
                      ]}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      {...formik.getFieldProps(`recommendationType`)}
                    />
                    {formik.touched?.recommendationType &&
                      formik.errors?.recommendationType && (
                        <Error text={formik.errors?.recommendationType} />
                      )}
                  </div>
                  <div>
                    <Label
                      htmlFor="recommendationName"
                      className="text-sm font-medium text-gray-700"
                    >
                      Recommendation Name
                    </Label>
                    <Input
                      id="recommendationName"
                      type="text"
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Recommendation Name"
                      {...formik.getFieldProps(`recommendationName`)}
                    />
                    {formik.touched?.recommendationName &&
                      formik.errors?.recommendationName && (
                        <Error text={formik.errors?.recommendationName} />
                      )}
                  </div>

                  <div>
                    <Label
                      htmlFor="affilationLink"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Link
                    </Label>
                    <Input
                      id="affilationLink"
                      type="text"
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Drop the link for your recommendation"
                      {...formik.getFieldProps(`affilationLink`)}
                    />
                    {formik.touched?.affilationLink &&
                      formik.errors?.affilationLink && (
                        <Error text={formik.errors?.affilationLink} />
                      )}
                  </div>

                  {(formik.values.recommendationType === "Restaurants" ||
                    formik.values.recommendationType === "Destination" ||
                    formik.values.recommendationType === "Others") && (
                    <div>
                      <Label
                        htmlFor="location"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Location
                      </Label>
                      <Input
                        id="location"
                        type="text"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Paste link from map"
                        {...formik.getFieldProps(`location`)}
                      />
                      {formik.touched?.location && formik.errors?.location && (
                        <Error text={formik.errors?.location} />
                      )}
                    </div>
                  )}
                  <div>
                    <Label
                      htmlFor="affordable"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Affordability
                    </Label>
                    <HStack>
                      <Icon
                        icon="bx:rupee"
                        color={affordable >= 1 ? "#FFD700" : "#888c8c"}
                        height={32}
                        onClick={() => {
                          if (affordable === 1) setAffordable(0);
                          else setAffordable(1);
                        }}
                      />
                      <Icon
                        icon="bx:rupee"
                        color={affordable >= 2 ? "#FFD700" : "#888c8c"}
                        height={32}
                        onClick={() => {
                          setAffordable(2);
                        }}
                      />
                      <Icon
                        icon="bx:rupee"
                        color={affordable >= 3 ? "#FFD700" : "#888c8c"}
                        height={32}
                        onClick={() => {
                          setAffordable(3);
                        }}
                      />
                      <Icon
                        icon="bx:rupee"
                        color={affordable >= 4 ? "#FFD700" : "#888c8c"}
                        height={32}
                        onClick={() => {
                          setAffordable(4);
                        }}
                      />
                      <Icon
                        icon="bx:rupee"
                        color={affordable === 5 ? "#FFD700" : "#888c8c"}
                        height={32}
                        onClick={() => {
                          setAffordable(5);
                        }}
                      />
                    </HStack>
                  </div>
                  <VStack>
                    <Label className="text-sm font-normal">Description</Label>
                    <TextArea {...formik.getFieldProps(`description`)} />
                    {formik.touched?.description &&
                      formik.errors?.description && (
                        <Error text={formik.errors?.description} />
                      )}
                  </VStack>
                  <HStack className=" flex flex-row-reverse">
                    <SwitchButton
                      className="text-sm font-medium items-center m-1 flex flex-row"
                      value={hideName}
                      label="Be Anonymous"
                      onClick={() => {
                        setHideName(!hideName);
                      }}
                    />
                  </HStack>
                  <p className="text-xs text-right">
                    *Name can be still tracked from backend
                  </p>
                  <Icon icon="bx:rupee" color="eef1f6" height={32} />
                  <div className="mt-8">
                    <button
                      type="submit"
                      onClick={() => {
                        console.log(formik.errors);
                      }}
                      className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Upload Recommendation
                    </button>
                  </div>
                </VStack>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => {
          setOpen(!open);
        }}
        message="Recommendation Posted,Thank You!"
        action={action}
      />

      <div className="sticky z-10 bottom-0 left-0 right-0 flex py-4 items-center justify-around bg-white rounded-lg">
        <button
          onClick={() => navigate("/cult")}
          className={`px-4 py-2 rounded-lg bg-inherit`}
        >
          Home
        </button>

        <button
          onClick={() => navigate("/addrecommendation")}
          className={`px-4 py-2 rounded-lg  bg-indigo-500 text-white`}
        >
          Recommend
        </button>
        <button
          onClick={() => navigate("/addevent")}
          className={`px-4 py-2 rounded-lg bg-inherit`}
        >
          Event
        </button>
      </div>
    </div>
  );
};

export default SugestionCreation;
