import { Navigate, useRoutes } from "react-router-dom";
import GoogleSignIn from "./steps/GoogleSign";
import SharingPage from "./steps/SharingPage";
import EventPlanning from "./steps/EventPlanning";
import SugestionCreation from "./pages/SugestionCreation";
import HomeScreen from "./pages/HomeScreen";
import EventCreation from "./pages/EventCreation";

export default function Router() {
  const loggedIn = localStorage.getItem("name") || "";
  console.log(loggedIn);
  return useRoutes([
    {
      path: "/onboarding/login",
      element: <GoogleSignIn />,
    },
    {
      path: "/onboarding/sharing",
      element: <SharingPage />,
    },
    {
      path: "/onboarding/planning",
      element: <EventPlanning />,
    },
    {
      path: "/addrecommendation",
      element: loggedIn?.length > 1 ? <SugestionCreation /> : <SharingPage />,
    },
    {
      path: "/addevent",
      element: loggedIn?.length > 1 ? <EventCreation /> : <SharingPage />,
    },
    {
      path: "/home",
      element: <HomeScreen />,
    },

    {
      path: "/cult",
      element: <HomeScreen />,
    },
    { path: "*", element: <Navigate to="/onboarding/sharing" replace /> },
  ]);
}
function isAllowed(route: string) {
  // check from user object permissions
  return true;
}
