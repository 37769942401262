import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signOut,
  signInWithPopup,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { NavigateFunction } from "react-router";

const firebaseConfig = {
  apiKey: "AIzaSyDd2eKraxslrDSiSJcuY3kXIAy7vA1QL44",
  authDomain: "rzcult-c3c4a.firebaseapp.com",
  projectId: "rzcult-c3c4a",
  storageBucket: "rzcult-c3c4a.appspot.com",
  messagingSenderId: "97007658991",
  appId: "1:97007658991:web:4110c2b070942d6538381a",
  measurementId: "G-2392NMEHE7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

const provider = new GoogleAuthProvider();

export const signOutOfWebsite = () => {
  signOut(auth)
    .then(() => {
      localStorage.clear();
    })
    .catch((error) => {
      // An error happened.
    });
};

export const signInWithGoogle = (navigate: NavigateFunction) => {
  signInWithPopup(auth, provider)
    .then((result) => {
      const name = result.user.displayName || "";
      const email = result.user.email || "";
      const profilePic = result.user.photoURL || "";

      localStorage.setItem("name", name);
      localStorage.setItem("email", email);
      localStorage.setItem("profilePic", profilePic);
      navigate("/cult");
    })
    .catch((error) => {
      console.log(error);
    });
};
