import React, { useState, useEffect } from "react";
import RecommendationCard from "../card/Recommendation";
import { HStack, VStack } from "../shared/utility";
import { useNavigate } from "react-router";
import { useCultAuthStore } from "../steps/loginState";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { db, signOutOfWebsite } from "../firebase";
import Avatar from "../card/Avatar";
import { Tooltip } from "@mui/material";

export interface RecommendationModel {
  description: string;
  link: string;
  location: string;
  name: string;
  rupeeCount: number;
  type: string;
  userid: string;
  username: string;
}
const HomeScreen = () => {
  const cultAuthStore = useCultAuthStore();
  const navigate = useNavigate();
  const [recommendationCard, setRecommendationCard] =
    useState<RecommendationModel[]>();
  useEffect(() => {
    const unsub = onSnapshot(collection(db, "recommendation"), (snapshot) => {
      const recommendationCard: RecommendationModel[] = snapshot.docs.map(
        (doc) => doc.data()
      ) as RecommendationModel[];
      setRecommendationCard(recommendationCard);
    });
    return unsub;
  }, []);

  return (
    <div className="h-screen flex flex-col justify-between">
      <div className=" flex flex-col ">
        <HStack className=" px-4 py-2 -mx-12 sticky top-0 left-0 right-0 bg-white w-full  items-center justify-between shadow border-b border-gray-200 ">
          <img
            className="h-8 w-auto"
            src={
              "https://www.rulezero.com/wp-content/uploads/2021/08/logo_rulezero_dark.png"
            }
            alt="hissa_logo"
          />
          <p
            onClick={() => {
              signOutOfWebsite();
              navigate("/onboarding/login");
            }}
          >
            Log Out
          </p>
          <Avatar />
        </HStack>

        <VStack className="flex overflow-auto">
          {recommendationCard?.map((cardData) => (
            <RecommendationCard
              id={
                cardData.userid +
                cardData.name +
                cardData.link +
                cardData.description
              }
              username={cardData.username}
              name={cardData.name}
              type={cardData.type}
              description={cardData.description}
              price={cardData.rupeeCount}
              location={cardData.location}
              affiliationLink={cardData.link}
            />
          ))}
        </VStack>
      </div>

      <div className="sticky z-10 bottom-0 left-0 right-0 flex py-4 items-center justify-around bg-white rounded-lg">
        <button
          onClick={() => navigate("/cult")}
          className={`px-4 py-2 rounded-lg  bg-indigo-500 text-white`}
        >
          Home
        </button>

        <button
          onClick={() => navigate("/addrecommendation")}
          className={`px-4 py-2 rounded-lg bg-inherit`}
        >
          Recommend
        </button>
        <button
          onClick={() => navigate("/addevent")}
          className={`px-4 py-2 rounded-lg bg-inherit`}
        >
          Event
        </button>
      </div>
    </div>
  );
};

export default HomeScreen;
