import { LockClosedIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useNavigate } from "react-router";

const SharingPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col h-screen py-12 px-4 sm:px-6 lg:px-8 w-full max-w-md space-y-8 justify-between">
        <div className="flex flex-row justify-between">
          <img
            className="h-8 w-auto"
            src={"https://www.rulezero.com/wp-content/uploads/2021/08/logo_rulezero_dark.png"}
            alt="hissa_logo"
          />
        </div>
        <div>
          <img
            className="w-auto mx-auto"
            src={
              "https://img.freepik.com/free-vector/company-employees-sharing-thoughts-ideas_74855-5469.jpg?w=2000"
            }
            alt="sharing"
          />
        </div>
        <div>
          <h2 className="mt-6 text-left text-4xl font-bold tracking-tight text-gray-900">
            Sharing is caring
          </h2>
          <p className="mt-2 text-left text-base text-gray-600">
            Sharing is caring, let's spread joy and inspiration by suggesting
            our favorite places for food, vacations, books, movies and more.
          </p>
        </div>

        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center">
            <div className="w-8 h-2 rounded-full bg-gray-500 mx-1"></div>
            <div className="w-4 h-2 rounded-full border-gray-500 border mx-1"></div>
            <div className="w-4 h-2 rounded-full border-gray-500 border mx-1"></div>
          </div>
          <button
            type="submit"
            className="flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => {
              navigate("/onboarding/planning");
            }}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default SharingPage;
