import React from "react";
import { useNavigate } from "react-router";
import Avatar from "../card/Avatar";
import { HStack } from "../shared/utility";
import { CultAuthModel, useCultAuthStore } from "../steps/loginState";

const EventCreation = () => {
  const navigate = useNavigate();
  const cultAuthStore = useCultAuthStore();
  return (
    <div className="h-screen flex flex-col justify-between">
      <div className=" flex flex-col ">
        <HStack className="px-4 py-2 -mx-12 sticky top-0 left-0 right-0 bg-white w-full  items-center justify-between shadow border-b border-gray-200 ">
          <img
            className="h-8 w-auto"
            src={
              "https://www.rulezero.com/wp-content/uploads/2021/08/logo_rulezero_dark.png"
            }
            alt="hissa_logo"
          />

          <Avatar />
        </HStack>
        <div className="text-indigo-500 mt-96 text-lg font-semibold text-center">
          Coming Soon...
        </div>
      </div>
      <div className="sticky z-10 bottom-0 left-0 right-0 flex py-4 items-center justify-around bg-white rounded-lg">
        <button
          className={`px-4 py-2 rounded-lg bg-inherit`}
          onClick={() => navigate("/cult")}
        >
          Home
        </button>
        <button
          onClick={() => navigate("/addrecommendation")}
          className={`px-4 py-2 rounded-lg bg-inherit`}
        >
          Recommend
        </button>
        <button
          onClick={() => navigate("/addevent")}
          className={`px-4 py-2 rounded-lg  bg-indigo-500 text-white`}
        >
          Event
        </button>
      </div>
    </div>
  );
};

export default EventCreation;
