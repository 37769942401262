import { useState } from "react";
import { Center } from "../shared/utility";

function Avatar() {
  const [full, setFull] = useState(false);
  const username = localStorage.getItem("name") || "";
  return (
    <div>
      {full ? (
        <p
          className="text-base font-bold text-gray-600"
          onClick={() => {
            setFull(!full);
          }}
        >
          {username}
        </p>
      ) : (
        <Center
          onClick={() => setFull(!full)}
          className="relative w-8 h-8 loader-linear cursor-pointer"
        >
          <Center
            style={{
              backgroundColor: "#4f46e5",
              color: "ffffff",
            }}
            className="flex w-8 h-8 rounded-full "
          >
            <img
              src={localStorage.getItem("profilePic") || ""}
              alt="profilePic"
            />
          </Center>
        </Center>
      )}
    </div>
  );
}
export default Avatar;
